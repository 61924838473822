.groupSeparator{
    font-size:16px;
    color:#999;
    width:80%;
    margin:20px auto;
    overflow:hidden;
    text-align:center;
    line-height:1.2em;
}

.groupSeparator:before, .groupSeparator:after{
    content:"";
    vertical-align:top;
    display:inline-block;
    width:50%;
    height:0.65em;
    border-bottom:1px solid #ccc;
    margin:0 2% 0 -55%;
}
.groupSeparator:after{
    margin:0 -55% 0 2%;
}