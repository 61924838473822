  .pageBlockDiv {
    display: inline-block;
    width: 100%;
  }

  .pageBlockDiv .center {
    width:100%;
    max-width:660px;
    margin-left:auto;
    margin-right:auto;
    margin-top: 30px;
  }