:root {
    --f7-theme-color: #2b3452;
    --f7-theme-color-rgb: 0, 122, 255;
    --f7-theme-color-shade: #1E2439;
    --f7-theme-color-tint: #364369;
    --f7-bars-bg-color:  #2b3452;
    --f7-bars-link-color: #ffffff;
    --f7-bars-text-color: #ffffff;
    --f7-safe-area-left: 0px;
    --f7-safe-area-right: 0px;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    --f7-safe-area-outer-left: 0px;
    --f7-safe-area-outer-right: 0px;
    --f7-device-pixel-ratio: 1;
    --f7-block-title-white-space: normal;
    --f7-page-bg-color:rgb(245, 245, 245);
  }

  @media only screen and (min-width: 768px) {
    .hideFullScreen{
      display: none !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .hideMobileScreen {
      display: none !important;
    }
  }

  h2 {
    color: #000;
    text-align: center;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    font-weight: 500;
  }

  h3 {
    color: var(--f7-theme-color);
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    font-weight: 500;
  }

  h5 {
    color: var(--f7-theme-color);
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    font-weight: 500;
  }

  .groupSeparator{
    font-size:16px;
    color:#999;
    width:80%;
    margin:20px auto;
    overflow:hidden;
    text-align:center;
    line-height:1.2em;
  }
  
  .groupSeparator:before, .groupSeparator:after{
    content:"";
    vertical-align:top;
    display:inline-block;
    width:50%;
    height:0.65em;
    border-bottom:1px solid #999;
    margin:0 2% 0 -55%;
  }
  .groupSeparator:after{
    margin:0 -55% 0 2%;
  }

  .signin-google-g-logo {
    background: url(../img/icons/Google_G_Logo.svg) no-repeat center;
    background-size: 50% auto;
  }

  .signin-facebook-f-logo {
    background: url(../img/icons/Facebook_F_icon.svg) no-repeat center;
    background-size: 50% auto;
  }

  .signin-linkedin-in-logo {
    background: url(../img/icons/Linkedin_in_icon.svg) no-repeat center;
    background-size: 50% auto;
  }